import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import PageTitle from "../../layouts/PageTitle";
import { isEqual } from "lodash";
import SecurityDepositEditForm from "./SecurityDepositEditForm";
import {
  getSecurityDepositDetails,
  updateSecurityDepositDetails,
} from "../../../services/SecurityDepositServices";

const SecurityDepositEdit = () => {
  const nav = useNavigate();
  const path = window.location.pathname.split("/");
  const UserId = path[path.length - 1];
  const [securityDeposit, setSecurityDeposit] = useState({});
  const [oldSecurityDeposit, setOldSecurityDeposit] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  // Format date in YYYY-MM-DD format
  const formatDate = (date) => {
    if (!date) return "";
    const d = new Date(date);
    return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(d.getDate()).padStart(2, "0")}`;
  };

  // Fetch security deposit details on mount
  useEffect(() => {
    const fetchSecurityDepositDetails = async () => {
      try {
        const res = await getSecurityDepositDetails(UserId);
        const data = res?.data?.data;
        console.log(data);

        const formattedData = {
          ...data,
          date: formatDate(data?.date),
          cheque_details: {
            ...data?.cheque_details,
            cheque_amount: String(data?.cheque_details?.cheque_amount || ""),
            cheque_date: formatDate(data?.cheque_details?.cheque_date),
          },
          online_transfer_details: {
            name: data?.online_transfer_details?.name,
            date: formatDate(data?.online_transfer_details?.date),
            amount: String(data?.online_transfer_details?.amount || ""),
          },
          card_details: {
            notes: data?.card_details?.notes,
            amount: String(data?.card_details?.amount || ""),
          },
        };

        setSecurityDeposit(formattedData);
        setOldSecurityDeposit(formattedData);
      } catch (err) {
        console.error("Error fetching security deposit details:", err);
        toast.error("Failed to fetch security deposit details.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchSecurityDepositDetails();
  }, [UserId]);

  // Prepare post data for API submission
  const preparePostData = () => {
    return {
      depositId: securityDeposit._id,
      date: securityDeposit?.date,
      amount: String(securityDeposit?.amount), // Ensure amount is a string
      description: securityDeposit?.description,
      property_id: securityDeposit?.property?._id,
      unit_id: securityDeposit?.unit?._id,
      voucher_number: securityDeposit?.voucher_number,
      payment_method: securityDeposit?.payment_method,

      images:
        securityDeposit?.images && Array.isArray(securityDeposit.images)
          ? securityDeposit.images.map((url) => ({ url }))
          : [], // Ensure images are objects with the 'url'
      cheque_details:
        securityDeposit?.payment_method === "cheque"
          ? {
              cheque_amount: String(
                securityDeposit?.cheque_details?.cheque_amount || ""
              ),
              ...securityDeposit?.cheque_details,
            }
          : undefined,

      // Include online_transfer_details and card_details conditionally based on payment method
      online_transfer_details:
        securityDeposit?.payment_method === "online"
          ? {
              amount: String(
                securityDeposit?.online_transfer_details?.amount || ""
              ),
              ...securityDeposit?.online_transfer_details,
            }
          : undefined,
      card_details:
        securityDeposit?.payment_method === "card"
          ? {
              amount: String(securityDeposit?.card_details?.amount || ""),
              ...securityDeposit?.card_details,
            }
          : undefined,
    };
  };

  // Handle form submission
  const onSubmit = async () => {
    if (isEqual(oldSecurityDeposit, securityDeposit)) {
      toast.error(
        "No changes made. Please edit the details before submitting."
      );
      return;
    }

    setIsLoading(true);
    const postData = preparePostData();
    // console.log(postData);

    try {
      await updateSecurityDepositDetails(postData);
      toast.success("Security deposit details updated successfully!");
      nav("/security-deposit-list"); // Navigate back to the list
    } catch (err) {
      console.log("Error updating security deposit details:", err);
      toast.error("Failed to update security deposit details.");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div id="preloader">
        <div className="sk-three-bounce">
          <div className="sk-child sk-bounce1"></div>
          <div className="sk-child sk-bounce2"></div>
          <div className="sk-child sk-bounce3"></div>
        </div>
      </div>
    );
  }

  return (
    <>
      <PageTitle
        activeMenu="Edit security deposit"
        activePath="edit"
        motherMenu="Security Deposit List"
        motherPath="security-deposit-list"
      />
      <SecurityDepositEditForm
        nav={nav}
        onSubmit={onSubmit}
        securityDeposit={securityDeposit}
        setSecurityDeposit={setSecurityDeposit}
      />
    </>
  );
};

export default SecurityDepositEdit;
