import { useState } from "react";

export default function SecurityDepositEditForm({
  securityDeposit,
  setSecurityDeposit,
  onSubmit,
  nav,
}) {
  const [errors, setErrors] = useState({});
  const [paymentMethod, setPaymentMethod] = useState(
    securityDeposit?.payment_method || ""
  );

  const validateField = (name, value) => {
    let fieldErrors = { ...errors };
    if (!value) {
      fieldErrors[name] = `${name.replace(/_/g, " ")} is required`;
    } else {
      delete fieldErrors[name];
    }
    setErrors(fieldErrors);
  };

  const validateForm = () => {
    let newErrors = {};
    const requiredFields = [
      "date",
      "amount",
      "description",
      "voucher_number",
      "payment_method",
    ];

    // Validate common fields
    requiredFields.forEach((field) => {
      if (!securityDeposit[field]) {
        newErrors[field] = `${field.replace(/_/g, " ")} is required`;
      }
    });

    // Validate conditional fields based on payment method
    if (paymentMethod === "card") {
      if (!securityDeposit.card_details?.notes) {
        newErrors["card_details.notes"] = "Card notes are required";
      }
      if (!securityDeposit.card_details?.amount) {
        newErrors["card_details.amount"] = "Card amount is required";
      }
    } else if (paymentMethod === "cheque") {
      if (!securityDeposit.cheque_details?.cheque_date) {
        newErrors["cheque_details.cheque_date"] = "Cheque date is required";
      }
      if (!securityDeposit.cheque_details?.cheque_amount) {
        newErrors["cheque_details.cheque_amount"] = "Cheque amount is required";
      }
      if (!securityDeposit.cheque_details?.signed_by) {
        newErrors["cheque_details.signed_by"] = "Signed by is required";
      }
      if (!securityDeposit.cheque_details?.notes) {
        newErrors["cheque_details.notes"] = "Cheque notes are required";
      }
    } else if (paymentMethod === "online") {
      if (!securityDeposit.online_transfer_details?.date) {
        newErrors["online_transfer_details.date"] =
          "Online transfer date is required";
      }
      if (!securityDeposit.online_transfer_details?.amount) {
        newErrors["online_transfer_details.amount"] =
          "Online transfer amount is required";
      }
      if (!securityDeposit.online_transfer_details?.name) {
        newErrors["online_transfer_details.name"] =
          "Online transfer name is required";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleFormSubmit = () => {
    if (validateForm()) {
      onSubmit();
    }
  };

  const handlePaymentMethodChange = (e) => {
    const value = e.target.value;
    setPaymentMethod(value);

    setSecurityDeposit((prevState) => ({
      ...prevState,
      payment_method: value,
      cheque_details:
        value === "cheque"
          ? prevState.cheque_details
          : prevState.cheque_details,
      card_details:
        value === "card" ? prevState.card_details : prevState.card_details,
      online_transfer_details:
        value === "online"
          ? prevState.online_transfer_details
          : prevState.online_transfer_details,
    }));
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Edit Security Deposit Details</h4>
            </div>
            <div className="card-body">
              <form>
                <div className="row">
                  {/* Deposit ID */}

                  {/* Date */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Date
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={securityDeposit?.date || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          date: e.target.value,
                        });
                        validateField("date", e.target.value);
                      }}
                    />
                    {errors.date && (
                      <div className="text-danger">{errors.date}</div>
                    )}
                  </div>

                  {/* Amount */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Amount
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      value={securityDeposit?.amount || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          amount: e.target.value,
                        });
                        validateField("amount", e.target.value);
                      }}
                    />
                    {errors.amount && (
                      <div className="text-danger">{errors.amount}</div>
                    )}
                  </div>

                  {/* Description */}
                  <div className="mb-3 col-12">
                    <label className="form-label">
                      Description
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <textarea
                      className="form-control"
                      value={securityDeposit?.description || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          description: e.target.value,
                        });
                        validateField("description", e.target.value);
                      }}
                    />
                    {errors.description && (
                      <div className="text-danger">{errors.description}</div>
                    )}
                  </div>

                  {/* Voucher Number */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Voucher Number
                      <span className="text-danger">*</span>{" "}
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      value={securityDeposit?.voucher_number || ""}
                      onChange={(e) => {
                        setSecurityDeposit({
                          ...securityDeposit,
                          voucher_number: e.target.value,
                        });
                        validateField("voucher_number", e.target.value);
                      }}
                    />
                    {errors.voucher_number && (
                      <div className="text-danger">{errors.voucher_number}</div>
                    )}
                  </div>

                  {/* Payment Method */}
                  <div className="mb-3 col-lg-6 col-md-6">
                    <label className="form-label">
                      Payment Method <span className="text-danger">*</span>{" "}
                    </label>
                    <select
                      className="form-control"
                      value={paymentMethod}
                      onChange={handlePaymentMethodChange}
                    >
                      <option value="cash">Cash</option>
                      <option value="card">Card</option>
                      <option value="online">Online Transfer</option>
                      <option value="cheque">Cheque</option>
                    </select>
                  </div>

                  {/* Conditional Fields */}
                  {paymentMethod === "card" && (
                    <>
                      <div className="mb-3 col-12">
                        <label className="form-label">
                          Card Notes <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter card notes"
                          value={securityDeposit.card_details?.notes || ""}
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                notes: e.target.value,
                              },
                            });
                            validateField("card_details.notes", e.target.value);
                          }}
                        />
                        {errors["card_details.notes"] && (
                          <div className="text-danger">
                            {errors["card_details.notes"]}
                          </div>
                        )}{" "}
                      </div>
                      <div className="mb-3 col-12">
                        <label className="form-label">
                          Amount <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter amount"
                          value={
                            securityDeposit.card_details?.amount.toString() ||
                            ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              card_details: {
                                ...securityDeposit.card_details,
                                amount: e.target.value,
                              },
                            });
                            validateField(
                              "card_details.amount",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      {errors["card_details.amount"] && (
                        <div className="text-danger">
                          {errors["card_details.amount"]}
                        </div>
                      )}{" "}
                    </>
                  )}

                  {paymentMethod === "cheque" && (
                    <>
                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Cheque Date <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={
                            securityDeposit.cheque_details?.cheque_date || ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                cheque_date: e.target.value,
                              },
                            });
                            validateField(
                              "cheque_details.cheque_date",
                              e.target.value
                            );
                          }}
                        />
                        {errors["cheque_details.cheque_date"] && (
                          <div className="text-danger">
                            {errors["cheque_details.cheque_date"]}
                          </div>
                        )}{" "}
                      </div>
                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Cheque Amount <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter cheque amount"
                          value={
                            securityDeposit.cheque_details?.cheque_amount.toString() ||
                            ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                cheque_amount: e.target.value,
                              },
                            });
                            validateField(
                              "cheque_details.cheque_amount",
                              e.target.value
                            );
                          }}
                        />
                        {errors["cheque_details.cheque_amount"] && (
                          <div className="text-danger">
                            {errors["cheque_details.cheque_amount"]}
                          </div>
                        )}{" "}
                      </div>

                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Signed by <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Signed by"
                          value={
                            securityDeposit.cheque_details?.signed_by || ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                signed_by: e.target.value,
                              },
                            });
                            validateField(
                              "cheque_details?.signed_by",
                              e.target.value
                            );
                          }}
                        />
                        {errors["cheque_details.signed_by"] && (
                          <div className="text-danger">
                            {errors["cheque_details.signed_by"]}
                          </div>
                        )}{" "}
                      </div>

                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Notes <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter notes"
                          value={securityDeposit.cheque_details?.notes || ""}
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              cheque_details: {
                                ...securityDeposit.cheque_details,
                                notes: e.target.value,
                              },
                            });
                            validateField(
                              "cheque_details.notes",
                              e.target.value
                            );
                          }}
                        />
                        {errors["cheque_details.notes"] && (
                          <div className="text-danger">
                            {errors["cheque_details.notes"]}
                          </div>
                        )}{" "}
                      </div>
                    </>
                  )}

                  {paymentMethod === "online" && (
                    <>
                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Date <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          placeholder="Enter date"
                          value={
                            securityDeposit.online_transfer_details?.date || ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                date: e.target.value,
                              },
                            });
                            validateField(
                              "online_transfer_details.date",
                              e.target.value
                            );
                          }}
                        />
                        {errors["online_transfer_details.date"] && (
                          <div className="text-danger">
                            {errors["online_transfer_details.date"]}
                          </div>
                        )}{" "}
                      </div>{" "}
                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Amount <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="number"
                          className="form-control"
                          placeholder="Enter amount"
                          value={
                            securityDeposit.online_transfer_details?.amount ||
                            ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                amount: e.target.value,
                              },
                            });
                            validateField(
                              "online_transfer_details.amount",
                              e.target.value
                            );
                          }}
                        />
                        {errors["online_transfer_details.amount"] && (
                          <div className="text-danger">
                            {errors["online_transfer_details.amount"]}
                          </div>
                        )}{" "}
                      </div>{" "}
                      <div className="mb-3 col-6">
                        <label className="form-label">
                          Online Transfer Name
                          <span className="text-danger">*</span>{" "}
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter transfer name"
                          value={
                            securityDeposit.online_transfer_details?.name || ""
                          }
                          onChange={(e) => {
                            setSecurityDeposit({
                              ...securityDeposit,
                              online_transfer_details: {
                                ...securityDeposit.online_transfer_details,
                                name: e.target.value,
                              },
                            });
                            validateField(
                              "online_transfer_details.name",
                              e.target.value
                            );
                          }}
                        />
                      </div>
                      {errors["online_transfer_details.name"] && (
                        <div className="text-danger">
                          {errors["online_transfer_details.name"]}
                        </div>
                      )}{" "}
                    </>
                  )}
                </div>
              </form>

              {/* Submit and Cancel Buttons */}
              <div className="row">
                <div className="col-sm-12 pt-3">
                  <button
                    type="button"
                    className="btn btn-success me-3"
                    onClick={handleFormSubmit}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    onClick={() => nav(-1)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
