import axiosInstance from "./AxiosInstance";

export function approveRent(id, type) {
  return axiosInstance.get(
    `/api/cash-hand-over/manage?id=${id}&action=${type}`
  );
}

export function getNotApprovedList() {
  return axiosInstance.get(`/api/cash-hand-over`);
}
